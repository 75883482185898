import { Container } from "@mui/material"

import ProjectData from "./project_data.json"
import ProjectTitle from "../SharedComponents/ProjectTitle"
import IntroImage from "../SharedComponents/IntroImage"
import Details from "../SharedComponents/Details"
import ProjectOverview from "../SharedComponents/ProjectOverview"
import Needs from "../SharedComponents/Needs"
import Goal from "../SharedComponents/Goal"
import DesignProcess from "../SharedComponents/DesignProcess"
import DevelopmentProcess from "../SharedComponents/DevelopmentProcess"
import Retrospective from "../SharedComponents/Retrospective"
import { styles } from "./styles"
import "./styles.css"
import FinalProduct from "../SharedComponents/FinalProduct"

export default function HostFam() {
  return (
    <Container maxWidth="x1" sx={styles.projectPageContainer}>
      <ProjectTitle
        title={ProjectData.title}
        subTitle={ProjectData.subTitle}
        // projectLink={ProjectData.projectLink}
        // projectLinkTitle="Visit the Website"
      />
      <IntroImage img={ProjectData.imgSrc} />
      <Details details={ProjectData.details} />
      <ProjectOverview overview={ProjectData.overview} />
      <Needs needs={ProjectData.needs} />
      <Goal goals={ProjectData.goals} />
      <DesignProcess design={ProjectData.design} />
      <DevelopmentProcess development={ProjectData.development} />
      <FinalProduct finalProduct={ProjectData.finalProduct} />
      <Retrospective retrospectives={ProjectData.retrospectives} />
    </Container>
  )
}
